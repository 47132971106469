<template>
  <component
    :is="tag"
    v-click-away="() => visible && $emit('clickAway')"
    class="inline-block"
  >
    <div ref="triggerElement">
      <slot />
    </div>
    <transition name="dropdown" @ended="destroyPopper()">
      <div ref="contentElement" v-if="visible" class="inline-block absolute">
        <slot name="content" />
      </div>
    </transition>
  </component>
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  PropType,
  ref,
  watchEffect
} from 'vue';
import { createPopper, Instance, Placement } from '@popperjs/core';

export default defineComponent({
  name: 'Dropdown',
  props: {
    tag: { type: String, default: 'div' },
    visible: { type: Boolean, default: false },
    placement: { type: String as PropType<Placement>, default: 'bottom' },
    skidding: { type: Number, default: 0 },
    distance: { type: Number, default: 0 }
  },
  emits: ['clickAway'],
  setup(props) {
    const triggerElement = ref<HTMLElement | null>(null);
    const contentElement = ref<HTMLElement | null>(null);

    let popper: Instance | null = null;

    watchEffect(async () => {
      if (props.visible) {
        await nextTick();
        if (!triggerElement.value || !contentElement.value) {
          throw new Error('Dropdown refs are not present');
        }
        if (
          triggerElement.value.childElementCount !== 1 ||
          contentElement.value.childElementCount !== 1
        ) {
          throw new Error('Dropdown slots should supply a single element');
        }
        popper = createPopper(
          triggerElement.value.firstElementChild as HTMLElement,
          contentElement.value.firstElementChild as HTMLElement,
          {
            placement: props.placement,
            modifiers: [
              { name: 'offset', options: { offset: [props.skidding, props.distance] } }
            ]
          }
        );
      }
    });

    onBeforeUnmount(() => popper?.destroy());

    function destroyPopper() {
      if (!props.visible) {
        popper?.destroy();
      }
    }

    return {
      triggerElement,
      contentElement,
      destroyPopper
    };
  }
});
</script>

<style lang="postcss" scoped>
.dropdown-enter-active {
  @apply transition ease-out duration-100;
}
.dropdown-enter-from,
.dropdown-leave-to {
  @apply transform opacity-0 scale-75;
}

.dropdown-enter-to,
.dropdown-leave-from {
  @apply transform opacity-100 scale-100;
}
.dropdown-leave-active {
  @apply transition ease-in duration-75;
}
</style>
