
import { computed, DeepReadonly, defineComponent, PropType, ref } from 'vue';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { AugmentedSubmission, Submission } from '@/interfaces/interfaces';
import { useI18n } from '@/services/i18n';
import { logEvent } from '@/services/amplitude';

import SearchSubmissionInput from '@/components/program/SearchSubmissionInput.vue';

export default defineComponent({
  name: 'AssignedSubmissionsTab',
  props: {
    submissions: {
      type: Array as PropType<DeepReadonly<AugmentedSubmission[]>>,
      required: true
    }
  },
  components: {
    SearchSubmissionInput
  },
  setup(props) {
    const i18n = useI18n();
    const auth = useStore(authStore);
    const activeFilter = ref<'waiting-review' | 'reviewed'>('waiting-review');

    const columns = computed(() => {
      const baseColumns = [
        { name: 'name', label: i18n.t('name') },
        {
          name: 'id',
          label: i18n.t('applicantId')
        },
        { name: 'email', label: i18n.t('email') },
        { name: 'completedAt', label: i18n.t('snapshotCompletionDate') }
      ];
      if (activeFilter.value === 'waiting-review') {
        return baseColumns;
      } else if (activeFilter.value === 'reviewed') {
        return baseColumns.concat([
          {
            name: 'score',
            label: i18n.t('score')
          },
          {
            name: 'comment',
            label: i18n.t('comment')
          }
        ]);
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    const currentUsersSubmissions = computed(() => {
      if (auth.user === null) {
        throw new Error('No current user');
      }
      return props.submissions.filter(s => {
        return (
          s.submission.ratings !== undefined &&
          s.submission.ratings.length > 0 &&
          s.submission.ratings.some(r => r.userId === auth.user?.id)
        );
      });
    });

    const waitingReviewSubmissions = computed(() => {
      const submissions = currentUsersSubmissions.value.filter(s => {
        if (s.submission.ratings === undefined || auth.user === null) {
          return false;
        }
        const rating = s.submission.ratings?.find(r => r.userId === auth.user?.id);
        return rating?.score === null;
      });
      return submissions ?? [];
    });

    const reviewedSubmissions = computed(() => {
      const submissions = currentUsersSubmissions.value.filter(s => {
        if (s.submission.ratings === undefined || auth.user === null) {
          return false;
        }
        const rating = s.submission.ratings?.find(r => r.userId === auth.user?.id);
        return rating?.score !== null;
      });
      return submissions ?? [];
    });

    const activeSubmissionsList = computed(() => {
      if (activeFilter.value === 'waiting-review') {
        return waitingReviewSubmissions.value;
      } else if (activeFilter.value === 'reviewed') {
        return reviewedSubmissions.value;
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    function getUserScore(submission: Submission) {
      if (submission.ratings === undefined) {
        return null;
      }
      const usersRating = submission.ratings.find(r => r.userId === auth.user?.id);
      return usersRating?.score ?? null;
    }

    function getUserComment(submission: Submission) {
      if (submission.ratings === undefined) {
        return null;
      }
      const usersRating = submission.ratings.find(r => r.userId === auth.user?.id);
      if (usersRating === undefined) {
        return null;
      } else if (usersRating.comment && usersRating.comment.length > 99) {
        return usersRating.comment.slice(0, 99).concat('...');
      } else {
        return usersRating.comment;
      }
    }

    return {
      t: i18n.t,
      auth,
      logEvent,
      activeFilter,
      columns,
      waitingReviewSubmissions,
      reviewedSubmissions,
      activeSubmissionsList,
      getUserScore,
      getUserComment
    };
  }
});
