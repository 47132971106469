<template>
  <a-input type="text">
    <template #append>
      <img src="@/assets/icons/search.svg" alt="" class="ml-2" />
    </template>
  </a-input>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchInput'
});
</script>
