
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import { getLangName } from '@/services/langNames';

export default defineComponent({
  name: 'SchoolTestSelect',
  setup() {
    const program = useStore(programStore);
    const schools = computed(() => program.schools);
    const tests = computed(() => program.currentSchoolTests);

    const selectedSchoolId = computed({
      get: () => program.currentSchool?.id ?? '',
      set: program.selectSchool
    });

    const selectedTestId = computed(() => program.currentTest?.id ?? '');

    async function onTestSelected(testId: string) {
      await program.selectTest(testId);
    }

    return {
      t: useI18n().t,
      schools,
      tests,
      selectedSchoolId,
      selectedTestId,
      onTestSelected,
      getLangName,
      loadTask: program.loadSchoolsAndTests
    };
  }
});
