<template>
  <header class="text-white bg-blue-dark">
    <div
      class="container mx-auto px-4 md:px-0 flex justify-between items-center py-8 lg:py-12"
    >
      <div class="mb-4 text-3xl md:text-4xl">
        <h1 data-cy="applicants-title">{{ t('applicants') }}</h1>
      </div>
      <SchoolTestSelect class="flex flex-col" />
    </div>
  </header>
  <main class="container mx-auto">
    <div v-if="!isTestSelected" class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20">
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="select-combination-title">
          {{ t('selectCombinationTitle') }}
        </h2>
        <template #description>
          <p>{{ t('selectCombinationSubtitle') }}</p>
        </template>
      </ChartMessage>
    </div>
    <div
      v-else-if="!areResultsAvailable"
      class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20"
    >
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="results-not-available-title">
          {{ t('resultsComingSoonTitle') }}
        </h2>
        <template #description>
          <p>
            {{
              t('resultsComingSoonSubtitle', {
                availabilityDate: formattedResultsAvailableAt
              })
            }}
          </p>
        </template>
      </ChartMessage>
    </div>
    <div
      v-else-if="!programHasAccess"
      class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20"
    >
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="program-without-access-title">
          {{ t('resultsProgramHasNoAccessTitle') }}
        </h2>
        <template #description>
          <p>{{ t('resultsProgramHasNoAccessSubtitle') }}</p>
        </template>
      </ChartMessage>
    </div>
    <div v-else-if="!userHasAccess" class="w-full lg:w-3/5 2xl:w-1/2 mx-auto mt-24 mb-20">
      <ChartMessage>
        <h2 class="w-3/4 mx-auto" data-cy="user-without-access-title">
          {{ t('resultsUserHasNoAccessTitle') }}
        </h2>
        <template #description>
          <p>{{ t('resultsUserHasNoAccessSubtitle') }}</p>
        </template>
      </ChartMessage>
    </div>
    <div v-else-if="loadingData" class="w-full flex justify-center mt-24">
      <a-spinner />
    </div>
    <div v-else class="mt-20">
      <ul role="tablist" class="mb-6">
        <li
          v-if="isAdmin"
          id="manage-tab"
          role="tab"
          class="inline-block"
          :aria-selected="mode === 'manage'"
          aria-controls="manage-submissions-panel"
        >
          <button
            class="main-tab-btn mr-7"
            :class="{ active: mode === 'manage' }"
            @click="mode = 'manage'"
          >
            {{ t('manageTab') }}
          </button>
        </li>
        <li
          role="tab"
          class="inline-block"
          id="assigned-submissions-tab"
          :aria-selected="mode === 'assigned-submissions'"
          aria-controls="assigned-submissions-panel"
        >
          <button
            class="main-tab-btn"
            :class="{ active: mode === 'assigned-submissions' }"
            @click="mode = 'assigned-submissions'"
          >
            {{ t('assignedToMeTab') }}
          </button>
        </li>
      </ul>
      <div>
        <div
          v-if="mode === 'manage'"
          id="manage-submissions-panel"
          role="tabpanel"
          aria-labelledby="manage-tab"
        >
          <ManageSubmissionsTab
            :submissions="filteredSubmissions"
            @on-export-clicked="downloadCsv()"
          />
        </div>
        <div
          v-else-if="mode === 'assigned-submissions'"
          id="assigned-submissions-panel"
          role="tabpanel"
          aria-labelledby="assigned-submissions-tab"
        >
          <AssignedSubmissionsTab :submissions="filteredSubmissions" />
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import { unparse } from 'papaparse';
import { downloadFile, formatCsvDate } from '@/services/util';
import { initAmplitude, logEvent } from '@/services/amplitude';

import ChartMessage from '@/components/program/ChartMessage.vue';
import SchoolTestSelect from '@/components/program/SchoolTestSelect.vue';
import ManageSubmissionsTab from '@/components/program/ManageSubmissionsTab.vue';
import AssignedSubmissionsTab from '@/components/program/AssignedSubmissionsTab.vue';

export default defineComponent({
  name: 'ProgramApplicants',
  components: {
    ChartMessage,
    SchoolTestSelect,
    ManageSubmissionsTab,
    AssignedSubmissionsTab
  },
  setup() {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const isRater = computed(() => auth.isRater);
    const isAdmin = computed(() => auth.isSchoolAdmin);
    const i18n = useI18n();

    const mode = ref<'manage' | 'assigned-submissions'>('manage');

    const isTestSelected = computed(
      () => program.currentSchool !== undefined && program.currentTest !== undefined
    );

    const areResultsAvailable = computed(() => program.areResultsAvailable);
    const formattedResultsAvailableAt = computed(
      () => program.formattedResultsAvailableAt
    );
    const programHasAccess = computed(() => program.programHasAccess);
    const userHasAccess = computed(() => program.userHasAccess);

    const filteredSubmissions = computed(() => program.filteredSubmissions);

    const loadingData = computed(
      () => program.loadSubmissions.isRunning || program.loadRaters.isRunning
    );

    onMounted(() => {
      initAmplitude('program', auth.user?.id);
      if (isRater.value) {
        mode.value = 'assigned-submissions';
      }
    });

    async function downloadCsv() {
      const headers = [
        i18n.t('applicantName'),
        i18n.t('applicantEmail'),
        i18n.t('applicantId'),
        i18n.t('completionDate'),
        i18n.t('reviewers'),
        i18n.t('averageScore'),
        i18n.t('comments')
      ];
      const rows = program.augmentedSubmissions.map(row => [
        row.submission.user?.profile?.name,
        row.submission.user?.emails[0].address,
        row.submission.user?.id,
        formatCsvDate(row.submission.completedAt as string),
        row.raters
          ?.map(
            rater =>
              `${rater.profile?.name} | ${row.submission.ratings
                ?.filter(rating => rating.userId === rater.id)
                .map(r => r.score ?? '')}`
          )
          .join(' |\n'),
        row.averageScore,
        row.raters
          ?.map(
            rater =>
              `${rater.profile?.name} | ${row.submission.ratings
                ?.filter(rating => rating.userId === rater.id)
                .map(r => r.comment ?? '')}`
          )
          .join(' |\n')
      ]);
      const content = unparse({ fields: headers, data: rows });
      downloadFile(content, 'snapshot-submissions.csv', 'text/csv');

      logEvent({
        eventName: 'SS_EXPORT_RESULTS',
        userProperties: { userID: auth.user?.id },
        eventProperties: {
          programSelection: program.currentSchool?.id,
          programSelectionName: program.currentSchool?.name,
          snapshotTestId: program.currentTest?.id,
          snapshotTestVersion: program.currentTest?.name,
          snapshotTestLanguage: program.currentTest?.lang
        }
      });
    }

    return {
      t: i18n.t,
      isAdmin,
      isTestSelected,
      areResultsAvailable,
      formattedResultsAvailableAt,
      programHasAccess,
      userHasAccess,
      filteredSubmissions,
      loadingData,
      downloadCsv,
      mode
    };
  }
});
</script>

<style lang="postcss" scoped>
.main-tab-btn {
  @apply text-base text-gray-darker rounded p-3;

  &:hover,
  &:active {
    @apply text-blue-darkest bg-blue-lightest;
  }

  &:focus {
    @apply outline-none;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue;
  }

  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue;
  }

  &.active {
    @apply text-blue-darkest bg-blue-lightest;
  }
}
</style>

<i18n>
{
  "en": {
    "applicantEmail": "Applicant Email",
    "applicantId": "Applicant ID",
    "applicantName": "Applicant Name",
    "applicants": "Applicants",
    "assignedToMeTab": "Assigned to me",
    "averageScore": "Average Score",
    "comments": "Comments",
    "completionDate": "Completion Date",
    "manageTab": "Manage",
    "resultsComingSoonSubtitle": "Your applicants' Snapshot video responses will be available at {availabilityDate}",
    "resultsComingSoonTitle": "Coming soon!",
    "resultsProgramHasNoAccessSubtitle": "We cannot find any results based on the combination. The selected program did not opt-in to use the selected Snapshot test. Please try a different combination.",
    "resultsProgramHasNoAccessTitle": "No data available to display",
    "resultsUserHasNoAccessSubtitle": "You do not have access to Snapshot results for the selected program and test combination, please try a different combination or contact your administrator for permission.",
    "resultsUserHasNoAccessTitle": "No access to results",
    "reviewers": "Reviewers",
    "scores": "Scores",
    "selectCombinationSubtitle": "Before we can display the results, please select a program and admissions cycle from the above dropdown.",
    "selectCombinationTitle": "Please select a combination from the above menus"
  },
  "fr": {
    "applicantEmail": "Courriel du candidat",
    "applicantId": "ID du candidat",
    "applicantName": "Nom du candidat",
    "applicants": "Candidats",
    "assignedToMeTab": "Mes soumissions",
    "averageScore": "Score moyen",
    "comments": "Commentaires",
    "completionDate": "Complété le",
    "manageTab": "Gérer",
    "resultsComingSoonSubtitle": "Les réponses vidéos Snapshot de vos candidat(e)s seront disponibles le {availabilityDate}",
    "resultsComingSoonTitle": "Bientôt disponible !",
    "resultsProgramHasNoAccessSubtitle": "Nous n'avons pas trouvé de résultats correspondant à cette combination. Le programme sélectionné n'a pas opté pour l'utilisation de l'entrevue Snapshot sélectionnée. Veuillez essayer une combinaison différente.",
    "resultsProgramHasNoAccessTitle": "Pas de données disponibles",
    "resultsUserHasNoAccessSubtitle": "Vous n'avez pas accès aux résutlats Snapshot pour la combinaison de programmes et d'examens sélectionnées. Veuillez essayer une combinaison différente ou contacter votre administrateur pour une autorisation.",
    "resultsUserHasNoAccessTitle": "Aucun accès aux résultats",
    "reviewers": "Examinateurs",
    "scores": "Scores",
    "selectCombinationSubtitle": "Avant de pouvoir afficher des résultats, veuillez sélectionner un programme et une période d'admission dans le menu déroulant ci-dessus.",
    "selectCombinationTitle": "Veuillez sélectionner une combinaison d'options dans les menus ci-dessus."
  }
}
</i18n>
