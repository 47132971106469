
import { defineComponent, watch } from 'vue';
import { toMutable, useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import SearchInput from '@/components/common/SearchInput.vue';
import { debounceRef } from '@/services/util';
import { authStore } from '@/store/auth';
import { logEvent } from '@/services/amplitude';

export default defineComponent({
  name: 'SearchSubmissionInput',
  components: {
    SearchInput
  },
  setup() {
    const program = useStore(programStore);
    const auth = useStore(authStore);
    const searchString = debounceRef(toMutable(program.submissionsFilter), 400);

    watch(searchString, newQuery => {
      if (newQuery !== '') {
        logEvent({
          eventName: 'SS_SEARCHED_APPLICANT',
          userProperties: {
            userID: auth.user?.id
          },
          eventProperties: { searchValue: newQuery ?? undefined }
        });
      }
    });

    return {
      t: useI18n().t,
      searchString
    };
  }
});
