
import { computed, defineComponent, PropType } from '@vue/runtime-core';
import { User } from '@/interfaces/interfaces';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';

export default defineComponent({
  name: 'RaterBubble',
  props: {
    rater: {
      type: Object as PropType<User>,
      required: true
    }
  },
  setup(props) {
    const program = useStore(programStore);
    const raters = computed(() => program.raters);

    const colors = ['blue', 'red', 'teal', 'orange', 'baby-blue', 'green'];

    const raterColor = computed(() => {
      const raterIndex = raters.value?.indexOf(props.rater);
      if (raterIndex === undefined || raterIndex === -1) {
        throw new Error('Cannot find the index of provided rater');
      }
      return colors[raterIndex % colors.length];
    });

    return {
      raterColor
    };
  }
});
