export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant ID"])},
        "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
        "assignApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign applicants"])},
        "assignModalContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Are you sure you want to assign <strong>", _interpolate(_named("submissionNum")), "</strong> applicants to selected raters?</p><p>If the applicants have been assigned to yourself, you can find them in the “Assigned to me” tab.</p>"])},
        "assignSelectedApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign selected applicants"])},
        "averageScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Score"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
        "failedToAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not assign these reviewers to selected applicants"])},
        "inReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-review"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewer(s)"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
        "snapshotCompletionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot Completion Date"])},
        "srAddReviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add reviewer"])},
        "srSelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all submissions"])},
        "srSelectSubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select submission"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
        "successfullyAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully assigned reviewers to selected applicants"])},
        "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unassigned"])},
        "waitingForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for review"])}
      },
      "fr": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du candidat"])},
        "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigner"])},
        "assignApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigner les candidats"])},
        "assignModalContent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Êtes-vous sûr de vouloir assigner <strong>", _interpolate(_named("submissionNum")), "</strong>des candidat(e)s aux évaluateurs(trices) sélectionné(e)s?</p><p>Si les candidat(e)s vous ont été assigné(e)s, vous pouvez les trouver dans l’onglet « Mes soumissions ».</p>"])},
        "assignSelectedApplicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigner les candidats sélectionnés"])},
        "averageScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score moyen"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
        "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
        "failedToAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne pouvons pas assigner ces examinateurs aux candidats sélectionnés"])},
        "inReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours d'évaluation"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateur(s)"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
        "snapshotCompletionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot complété le"])},
        "srAddReviewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un examinateur"])},
        "srSelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner toutes les soumissions"])},
        "srSelectSubmission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la soumission"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
        "successfullyAssigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez assigné des examinateurs aux candidats sélectionnés"])},
        "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiré"])},
        "waitingForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente d'évaluation"])}
      }
    }
  })
}
