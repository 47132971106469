<template>
  <div v-if="loadTask.isRunning" class="flex items-center text-gray-light">
    <a-spinner variant="neutral" class="mr-4" />
    Loading...
  </div>
  <div v-else>
    <div>
      <a-select
        v-model="selectedSchoolId"
        :label="t('program')"
        data-cy="select-program-dropdown"
      >
        <option value="" selected disabled>
          {{ t('selectFromList') }}
        </option>
        <option v-for="school in schools" :value="school.id" :key="school.id">
          {{ school.name }}
        </option>
      </a-select>
    </div>

    <div class="mt-4">
      <a-select
        :model-value="selectedTestId"
        @change="onTestSelected($event.target.value)"
        :label="t('cycleTestLang')"
        :disabled="selectedSchoolId === ''"
        data-cy="select-test-dropdown"
      >
        <option value="" selected disabled>
          {{ t('selectFromList') }}
        </option>
        <option v-for="test in tests" :value="test.id" :key="test.id">
          {{ test.cycle?.name }} &mdash; {{ test.name }} {{ getLangName(test.lang) }}
        </option>
      </a-select>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import { getLangName } from '@/services/langNames';

export default defineComponent({
  name: 'SchoolTestSelect',
  setup() {
    const program = useStore(programStore);
    const schools = computed(() => program.schools);
    const tests = computed(() => program.currentSchoolTests);

    const selectedSchoolId = computed({
      get: () => program.currentSchool?.id ?? '',
      set: program.selectSchool
    });

    const selectedTestId = computed(() => program.currentTest?.id ?? '');

    async function onTestSelected(testId: string) {
      await program.selectTest(testId);
    }

    return {
      t: useI18n().t,
      schools,
      tests,
      selectedSchoolId,
      selectedTestId,
      onTestSelected,
      getLangName,
      loadTask: program.loadSchoolsAndTests
    };
  }
});
</script>

<i18n>
{
  "en": {
    "cycleTestLang": "Admission cycle, test and language",
    "program": "Program",
    "selectFromList": "Select from list"
  },
  "fr": {
    "cycleTestLang": "Période d'admission, examen et langue",
    "program": "Programme",
    "selectFromList": "Sélectionner dans la liste"
  }
}
</i18n>
