
import {
  computed,
  DeepReadonly,
  defineComponent,
  PropType,
  ref,
  watch,
  watchEffect
} from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { AugmentedSubmission } from '@/interfaces/interfaces';
import { injectToasts } from '../common/toasts/toasts';
import { logEvent } from '@/services/amplitude';

import DashedButton from '@/components/common/DashedButton.vue';
import SearchSubmissionInput from '@/components/program/SearchSubmissionInput.vue';
import RaterBubble from '@/components/program/RaterBubble.vue';
import AssignRatersDropdown from '@/components/program/AssignRatersDropdown.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import Modal from '@/components/common/modal/Modal.vue';
import { injectModals } from '../common/modal/modal';

export default defineComponent({
  name: 'ManageSubmissionsTab',
  props: {
    submissions: {
      type: Array as PropType<DeepReadonly<AugmentedSubmission[]>>,
      required: true
    }
  },
  components: {
    DashedButton,
    SearchSubmissionInput,
    RaterBubble,
    AssignRatersDropdown,
    Dropdown,
    Modal
  },
  emits: ['onExportClicked'],
  setup(props, { emit }) {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const i18n = useI18n();
    const toasts = injectToasts();
    const useMultiReviewers = computed(() => program.useMultiReviewers);
    const raters = computed(() => program.raters);

    const modals = injectModals();
    const modal = modals.get('multipleAssignModal');

    const activeFilter = ref<'unassigned' | 'waiting-review' | 'in-review' | 'reviewed'>(
      'unassigned'
    );

    const unassignedSubmissions = computed(() =>
      props.submissions.filter(
        s => s.submission?.ratings === undefined || s.submission?.ratings.length === 0
      )
    );
    const waitingReviewSubmissions = computed(() => {
      return props.submissions.filter(
        s =>
          s.submission?.ratings !== undefined &&
          s.submission?.ratings.length > 0 &&
          s.submission?.ratings.every(r => r.score === null)
      );
    });
    const inReviewSubmissions = computed(() => {
      return props.submissions.filter(s => {
        if (s.submission?.ratings === undefined) {
          return false;
        }
        let rated = 0;
        for (const r of s.submission?.ratings) {
          if (r.score !== null) {
            rated++;
          }
        }
        return rated > 0 && rated < s.submission?.ratings.length;
      });
    });
    const reviewedSubmissions = computed(() => {
      return props.submissions.filter(
        s =>
          s.submission?.ratings !== undefined &&
          s.submission?.ratings.length > 0 &&
          s.submission?.ratings.every(r => r.score !== null)
      );
    });

    const activeSubmissionsList = computed(() => {
      if (activeFilter.value === 'unassigned') {
        return unassignedSubmissions.value;
      } else if (activeFilter.value === 'waiting-review') {
        return waitingReviewSubmissions.value;
      } else if (activeFilter.value === 'in-review') {
        return inReviewSubmissions.value;
      } else if (activeFilter.value === 'reviewed') {
        return reviewedSubmissions.value;
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    const columns = computed(() => {
      const baseColumns = [
        { name: 'checkbox' },
        { name: 'name', label: i18n.t('name') },
        {
          name: 'id',
          label: i18n.t('applicantId')
        },
        { name: 'email', label: i18n.t('email') },
        { name: 'completedAt', label: i18n.t('snapshotCompletionDate') },
        { name: 'reviewers', label: i18n.t('reviewers') }
      ];
      if (!useMultiReviewers.value) {
        baseColumns.shift();
      }
      if (!useMultiReviewers.value && activeFilter.value === 'unassigned') {
        baseColumns.pop();
      }
      if (
        activeFilter.value === 'unassigned' ||
        activeFilter.value === 'waiting-review'
      ) {
        return baseColumns;
      } else if (
        activeFilter.value === 'in-review' ||
        activeFilter.value === 'reviewed'
      ) {
        return baseColumns.concat([
          {
            name: 'scores',
            label: i18n.t('scores')
          },
          {
            name: 'averageScore',
            label: i18n.t('averageScore')
          }
        ]);
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    const toggleSelectAll = ref<boolean>(false);

    const selectedSubmissions = ref<Array<boolean | null>>([]);
    const selectedSubmissionIds = computed(() =>
      selectedSubmissions.value.flatMap((selected, index) =>
        selected ? activeSubmissionsList.value[index].submission.id : []
      )
    );

    const showDropdown = ref(false);
    const showSingleSubmissionDropdown = ref<string | null>(null);
    const assignIsRunning = computed(() => program.assignRaters.isRunning);

    function toggleSingleSubmissionDropdown(submissionId: string) {
      showSingleSubmissionDropdown.value =
        showSingleSubmissionDropdown.value !== submissionId ? submissionId : null;
    }

    const raterIds = ref<string[]>([]);

    async function assignRaters(rIds: string[], submissionId?: string) {
      if (submissionId !== undefined) {
        await program.assignRaters.run([submissionId], rIds);
      } else {
        await program.assignRaters.run(selectedSubmissionIds.value, rIds);
      }

      if (program.assignRaters.error !== null) {
        toasts.push({
          title: i18n.t('error'),
          content: i18n.t('failedToAssign'),
          variant: 'danger'
        });
        throw program.assignRaters.error;
      }

      logEvent({
        eventName:
          showSingleSubmissionDropdown.value === null
            ? 'SS_ASSIGN_SELECTED_REVIEWERS'
            : 'SS_ADD_REVIEWERS',
        userProperties: { userID: auth.user?.id },
        eventProperties: {
          reviewerUserId: rIds,
          tab: activeFilter.value
        }
      });

      selectedSubmissions.value = [];
      toggleSelectAll.value = false;
      showSingleSubmissionDropdown.value = null;
      showDropdown.value = false;
      raterIds.value = [];
      toasts.push({
        title: i18n.t('success'),
        content: i18n.t('successfullyAssigned'),
        variant: 'success'
      });
    }

    async function openConfirmationModal(ids: string[]) {
      raterIds.value = ids;
      await modal.value?.open();
    }

    async function assignToMultipleSubmissions() {
      await assignRaters(raterIds.value);
      modal.value?.accept();
      raterIds.value = [];
    }

    function downloadCsv() {
      emit('onExportClicked');
    }

    watchEffect(() => {
      selectedSubmissions.value = toggleSelectAll.value
        ? new Array(activeSubmissionsList.value.length).fill(true)
        : [];
    });

    watch(activeFilter, () => {
      selectedSubmissions.value = [];
      toggleSelectAll.value = false;
    });

    return {
      t: i18n.t,
      auth,
      logEvent,
      activeFilter,
      useMultiReviewers,
      unassignedSubmissions,
      waitingReviewSubmissions,
      inReviewSubmissions,
      reviewedSubmissions,
      columns,
      toggleSelectAll,
      selectedSubmissions,
      selectedSubmissionIds,
      activeSubmissionsList,
      raters,
      downloadCsv,
      showDropdown,
      showSingleSubmissionDropdown,
      toggleSingleSubmissionDropdown,
      assignRaters,
      assignIsRunning,
      openConfirmationModal,
      assignToMultipleSubmissions
    };
  }
});
