<template>
  <div class="flex">
    <div class="w-1/3">
      <SearchSubmissionInput />
    </div>
    <div class="ml-auto">
      <Dropdown
        v-if="useMultiReviewers"
        :visible="showDropdown"
        @click-away="showDropdown = false"
        :distance="4"
        placement="bottom-start"
      >
        <a-button
          variant="outline"
          class="mr-3"
          @click="showDropdown = !showDropdown"
          :disabled="assignIsRunning"
          id="assign-applicants-button"
        >
          <div>
            <span>{{ t('assignSelectedApplicants') }}</span>
            <a-chevron
              class="w-4 transform rotate-90 ml-2 -mt-1 inline-block"
            ></a-chevron>
          </div>
        </a-button>
        <template #content>
          <AssignRatersDropdown
            v-if="raters !== null"
            :raters="raters"
            aria-labelledby="assign-applicants-button"
            @on-assign="openConfirmationModal($event)"
          />
        </template>
      </Dropdown>
      <Modal name="multipleAssignModal">
        <template #header>
          {{ t('assignApplicants') }}
        </template>
        <template #content>
          <div
            class="mb-10 prose"
            v-html="
              t('assignModalContent', { submissionNum: selectedSubmissionIds.length })
            "
          ></div>
        </template>
        <template #footer="{ dismiss }">
          <div class="flex justify-end">
            <a-button variant="outline" :disabled="assignIsRunning" @click="dismiss()">
              {{ t('cancel') }}
            </a-button>
            <a-button
              class="ml-2"
              @click="assignToMultipleSubmissions()"
              :disabled="assignIsRunning"
            >
              <div v-if="assignIsRunning" class="px-3">
                <a-spinner size="1.2rem" variant="neutral" />
              </div>
              <span v-else>{{ t('assign') }}</span>
            </a-button>
          </div>
        </template>
      </Modal>
      <a-button @click="downloadCsv()" variant="outline" class="inline-block">
        <span>{{ t('export') }}</span>
      </a-button>
    </div>
  </div>
  <ul class="flex tab-list mt-6">
    <li>
      <button
        :class="{ 'tab-active': activeFilter === 'unassigned' }"
        @click="activeFilter = 'unassigned'"
      >
        {{ t('unassigned') }} ({{ unassignedSubmissions.length }})
      </button>
    </li>
    <li>
      <button
        :class="{ 'tab-active': activeFilter === 'waiting-review' }"
        @click="activeFilter = 'waiting-review'"
      >
        {{ t('waitingForReview') }} ({{ waitingReviewSubmissions.length }})
      </button>
    </li>
    <li v-if="useMultiReviewers">
      <button
        :class="{ 'tab-active': activeFilter === 'in-review' }"
        @click="activeFilter = 'in-review'"
      >
        {{ t('inReview') }} ({{ inReviewSubmissions.length }})
      </button>
    </li>
    <li>
      <button
        :class="{ 'tab-active': activeFilter === 'reviewed' }"
        @click="activeFilter = 'reviewed'"
      >
        {{ t('reviewed') }} ({{ reviewedSubmissions.length }})
      </button>
    </li>
  </ul>
  <div class="overflow-x-auto mt-8">
    <a-table
      :columns="columns"
      :data="activeSubmissionsList"
      row-key="id"
      data-cy="applicants-data-table"
    >
      <template #header-checkbox>
        <div class="-mr-2 flex justify-center">
          <a-checkbox v-model="toggleSelectAll">
            <span class="sr-only">{{ t('srSelectAll') }}</span>
            <span aria-hidden="true" class="invisible">i</span>
          </a-checkbox>
        </div>
      </template>
      <template #header-averageScore>
        <div class="text-center">
          {{ t('averageScore') }}
        </div>
      </template>
      <template #cell-checkbox="{ index }">
        <div class="-mr-2 flex justify-center">
          <a-checkbox v-model="selectedSubmissions[index]">
            <span class="sr-only">{{ t('srSelectSubmission') }}</span>
            <span aria-hidden="true" class="invisible">i</span>
          </a-checkbox>
        </div>
      </template>
      <template #cell-name="{ row }">
        <router-link
          :to="{
            name: 'program.submission',
            params: { submissionId: row.submission.id }
          }"
          @click="
            logEvent({
              eventName: 'SS_CLICKED_TO_REVIEW_APP',
              userProperties: {
                userID: auth.user?.id
              },
              eventProperties: { applicantUserId: row.submission.userId }
            })
          "
          class="link"
        >
          {{ row.submission.user.profile?.name }}
        </router-link>
      </template>
      <template #cell-id="{ row }">
        {{ row.submission.userId }}
      </template>
      <template #cell-email="{ row }">
        {{ row.submission.user.emails[0].address }}
      </template>
      <template #cell-completedAt="{ row }">
        {{ row.formattedCompletedAt }}
      </template>
      <template #cell-reviewers="{ row }">
        <Dropdown
          v-if="useMultiReviewers"
          :visible="showSingleSubmissionDropdown === row.submission.id"
          @click-away="showSingleSubmissionDropdown = null"
          :distance="4"
          placement="bottom-end"
          class="inline-block"
        >
          <DashedButton
            @click="toggleSingleSubmissionDropdown(row.submission.id)"
            :disabled="assignIsRunning"
          >
            <a-plus-icon class="mr-1">
              <title>{{ t('srAddReviewer') }}</title>
            </a-plus-icon>
            {{ t('add') }}
          </DashedButton>
          <template #content>
            <AssignRatersDropdown
              v-if="raters !== null"
              :raters="raters"
              :submission="row.submission"
              @on-assign="assignRaters($event, row.submission.id)"
            />
          </template>
        </Dropdown>
        <ul class="inline">
          <li
            class="inline-block ml-2 py-2 my-0.5"
            v-for="rater in row.raters"
            :key="rater.id"
          >
            <RaterBubble :rater="rater">
              {{
                rater.profile?.givenname.charAt(0).toUpperCase() +
                  rater.profile?.surname.charAt(0).toUpperCase()
              }}
            </RaterBubble>
          </li>
        </ul>
      </template>
      <template #cell-scores="{ row }">
        <ul v-if="row.submission.ratings !== undefined">
          <li
            v-for="rating in row.submission.ratings"
            :key="rating.id"
            class="inline-block mr-1"
          >
            {{ rating.score !== null ? `(${rating.score})` : null }}
          </li>
        </ul>
      </template>
      <template #cell-averageScore="{ row }">
        <div class="text-center">
          {{ row.averageScore }}
        </div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import {
  computed,
  DeepReadonly,
  defineComponent,
  PropType,
  ref,
  watch,
  watchEffect
} from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { AugmentedSubmission } from '@/interfaces/interfaces';
import { injectToasts } from '../common/toasts/toasts';
import { logEvent } from '@/services/amplitude';

import DashedButton from '@/components/common/DashedButton.vue';
import SearchSubmissionInput from '@/components/program/SearchSubmissionInput.vue';
import RaterBubble from '@/components/program/RaterBubble.vue';
import AssignRatersDropdown from '@/components/program/AssignRatersDropdown.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import Modal from '@/components/common/modal/Modal.vue';
import { injectModals } from '../common/modal/modal';

export default defineComponent({
  name: 'ManageSubmissionsTab',
  props: {
    submissions: {
      type: Array as PropType<DeepReadonly<AugmentedSubmission[]>>,
      required: true
    }
  },
  components: {
    DashedButton,
    SearchSubmissionInput,
    RaterBubble,
    AssignRatersDropdown,
    Dropdown,
    Modal
  },
  emits: ['onExportClicked'],
  setup(props, { emit }) {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const i18n = useI18n();
    const toasts = injectToasts();
    const useMultiReviewers = computed(() => program.useMultiReviewers);
    const raters = computed(() => program.raters);

    const modals = injectModals();
    const modal = modals.get('multipleAssignModal');

    const activeFilter = ref<'unassigned' | 'waiting-review' | 'in-review' | 'reviewed'>(
      'unassigned'
    );

    const unassignedSubmissions = computed(() =>
      props.submissions.filter(
        s => s.submission?.ratings === undefined || s.submission?.ratings.length === 0
      )
    );
    const waitingReviewSubmissions = computed(() => {
      return props.submissions.filter(
        s =>
          s.submission?.ratings !== undefined &&
          s.submission?.ratings.length > 0 &&
          s.submission?.ratings.every(r => r.score === null)
      );
    });
    const inReviewSubmissions = computed(() => {
      return props.submissions.filter(s => {
        if (s.submission?.ratings === undefined) {
          return false;
        }
        let rated = 0;
        for (const r of s.submission?.ratings) {
          if (r.score !== null) {
            rated++;
          }
        }
        return rated > 0 && rated < s.submission?.ratings.length;
      });
    });
    const reviewedSubmissions = computed(() => {
      return props.submissions.filter(
        s =>
          s.submission?.ratings !== undefined &&
          s.submission?.ratings.length > 0 &&
          s.submission?.ratings.every(r => r.score !== null)
      );
    });

    const activeSubmissionsList = computed(() => {
      if (activeFilter.value === 'unassigned') {
        return unassignedSubmissions.value;
      } else if (activeFilter.value === 'waiting-review') {
        return waitingReviewSubmissions.value;
      } else if (activeFilter.value === 'in-review') {
        return inReviewSubmissions.value;
      } else if (activeFilter.value === 'reviewed') {
        return reviewedSubmissions.value;
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    const columns = computed(() => {
      const baseColumns = [
        { name: 'checkbox' },
        { name: 'name', label: i18n.t('name') },
        {
          name: 'id',
          label: i18n.t('applicantId')
        },
        { name: 'email', label: i18n.t('email') },
        { name: 'completedAt', label: i18n.t('snapshotCompletionDate') },
        { name: 'reviewers', label: i18n.t('reviewers') }
      ];
      if (!useMultiReviewers.value) {
        baseColumns.shift();
      }
      if (!useMultiReviewers.value && activeFilter.value === 'unassigned') {
        baseColumns.pop();
      }
      if (
        activeFilter.value === 'unassigned' ||
        activeFilter.value === 'waiting-review'
      ) {
        return baseColumns;
      } else if (
        activeFilter.value === 'in-review' ||
        activeFilter.value === 'reviewed'
      ) {
        return baseColumns.concat([
          {
            name: 'scores',
            label: i18n.t('scores')
          },
          {
            name: 'averageScore',
            label: i18n.t('averageScore')
          }
        ]);
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    const toggleSelectAll = ref<boolean>(false);

    const selectedSubmissions = ref<Array<boolean | null>>([]);
    const selectedSubmissionIds = computed(() =>
      selectedSubmissions.value.flatMap((selected, index) =>
        selected ? activeSubmissionsList.value[index].submission.id : []
      )
    );

    const showDropdown = ref(false);
    const showSingleSubmissionDropdown = ref<string | null>(null);
    const assignIsRunning = computed(() => program.assignRaters.isRunning);

    function toggleSingleSubmissionDropdown(submissionId: string) {
      showSingleSubmissionDropdown.value =
        showSingleSubmissionDropdown.value !== submissionId ? submissionId : null;
    }

    const raterIds = ref<string[]>([]);

    async function assignRaters(rIds: string[], submissionId?: string) {
      if (submissionId !== undefined) {
        await program.assignRaters.run([submissionId], rIds);
      } else {
        await program.assignRaters.run(selectedSubmissionIds.value, rIds);
      }

      if (program.assignRaters.error !== null) {
        toasts.push({
          title: i18n.t('error'),
          content: i18n.t('failedToAssign'),
          variant: 'danger'
        });
        throw program.assignRaters.error;
      }

      logEvent({
        eventName:
          showSingleSubmissionDropdown.value === null
            ? 'SS_ASSIGN_SELECTED_REVIEWERS'
            : 'SS_ADD_REVIEWERS',
        userProperties: { userID: auth.user?.id },
        eventProperties: {
          reviewerUserId: rIds,
          tab: activeFilter.value
        }
      });

      selectedSubmissions.value = [];
      toggleSelectAll.value = false;
      showSingleSubmissionDropdown.value = null;
      showDropdown.value = false;
      raterIds.value = [];
      toasts.push({
        title: i18n.t('success'),
        content: i18n.t('successfullyAssigned'),
        variant: 'success'
      });
    }

    async function openConfirmationModal(ids: string[]) {
      raterIds.value = ids;
      await modal.value?.open();
    }

    async function assignToMultipleSubmissions() {
      await assignRaters(raterIds.value);
      modal.value?.accept();
      raterIds.value = [];
    }

    function downloadCsv() {
      emit('onExportClicked');
    }

    watchEffect(() => {
      selectedSubmissions.value = toggleSelectAll.value
        ? new Array(activeSubmissionsList.value.length).fill(true)
        : [];
    });

    watch(activeFilter, () => {
      selectedSubmissions.value = [];
      toggleSelectAll.value = false;
    });

    return {
      t: i18n.t,
      auth,
      logEvent,
      activeFilter,
      useMultiReviewers,
      unassignedSubmissions,
      waitingReviewSubmissions,
      inReviewSubmissions,
      reviewedSubmissions,
      columns,
      toggleSelectAll,
      selectedSubmissions,
      selectedSubmissionIds,
      activeSubmissionsList,
      raters,
      downloadCsv,
      showDropdown,
      showSingleSubmissionDropdown,
      toggleSingleSubmissionDropdown,
      assignRaters,
      assignIsRunning,
      openConfirmationModal,
      assignToMultipleSubmissions
    };
  }
});
</script>

<style lang="postcss" scoped>
.tab-list {
  @apply border-b border-gray-lighter;
  & li {
    @apply mr-4 md:mr-6 lg:mr-10;
    & button {
      @apply py-4 transition-colors duration-300;
      &:hover {
        @apply text-gray;
      }
      &:focus-visible {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }
      &:-moz-focusring {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }
      &:active {
        @apply text-blue;
      }
      &.tab-active {
        @apply text-blue cursor-default;
        box-shadow: rgb(77, 84, 160) 0 -0.2rem 0 inset;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "add": "Add",
    "applicantId": "Applicant ID",
    "assign": "Assign",
    "assignApplicants": "Assign applicants",
    "assignModalContent": "<p>Are you sure you want to assign <strong>{submissionNum}</strong> applicants to selected raters?</p><p>If the applicants have been assigned to yourself, you can find them in the “Assigned to me” tab.</p>",
    "assignSelectedApplicants": "Assign selected applicants",
    "averageScore": "Average Score",
    "cancel": "Cancel",
    "email": "Email",
    "error": "Error",
    "export": "Export",
    "failedToAssign": "We could not assign these reviewers to selected applicants",
    "inReview": "In-review",
    "name": "Name",
    "reviewed": "Reviewed",
    "reviewers": "Reviewer(s)",
    "scores": "Scores",
    "snapshotCompletionDate": "Snapshot Completion Date",
    "srAddReviewer": "Add reviewer",
    "srSelectAll": "Select all submissions",
    "srSelectSubmission": "Select submission",
    "success": "Success",
    "successfullyAssigned": "You have successfully assigned reviewers to selected applicants",
    "unassigned": "Unassigned",
    "waitingForReview": "Waiting for review"
  },
  "fr": {
    "add": "Ajouter",
    "applicantId": "ID du candidat",
    "assign": "Assigner",
    "assignApplicants": "Assigner les candidats",
    "assignModalContent": "<p>Êtes-vous sûr de vouloir assigner <strong>{submissionNum}</strong>des candidat(e)s aux évaluateurs(trices) sélectionné(e)s?</p><p>Si les candidat(e)s vous ont été assigné(e)s, vous pouvez les trouver dans l’onglet « Mes soumissions ».</p>",
    "assignSelectedApplicants": "Assigner les candidats sélectionnés",
    "averageScore": "Score moyen",
    "cancel": "Annuler",
    "email": "Courriel",
    "error": "Erreur",
    "export": "Exporter",
    "failedToAssign": "Nous ne pouvons pas assigner ces examinateurs aux candidats sélectionnés",
    "inReview": "En cours d'évaluation",
    "name": "Nom",
    "reviewed": "Évalué",
    "reviewers": "Examinateur(s)",
    "scores": "Scores",
    "snapshotCompletionDate": "Snapshot complété le",
    "srAddReviewer": "Ajouter un examinateur",
    "srSelectAll": "Sélectionner toutes les soumissions",
    "srSelectSubmission": "Sélectionner la soumission",
    "success": "Succès",
    "successfullyAssigned": "Vous avez assigné des examinateurs aux candidats sélectionnés",
    "unassigned": "Retiré",
    "waitingForReview": "En attente d'évaluation"
  }
}
</i18n>
