
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  PropType,
  ref,
  watchEffect
} from 'vue';
import { createPopper, Instance, Placement } from '@popperjs/core';

export default defineComponent({
  name: 'Dropdown',
  props: {
    tag: { type: String, default: 'div' },
    visible: { type: Boolean, default: false },
    placement: { type: String as PropType<Placement>, default: 'bottom' },
    skidding: { type: Number, default: 0 },
    distance: { type: Number, default: 0 }
  },
  emits: ['clickAway'],
  setup(props) {
    const triggerElement = ref<HTMLElement | null>(null);
    const contentElement = ref<HTMLElement | null>(null);

    let popper: Instance | null = null;

    watchEffect(async () => {
      if (props.visible) {
        await nextTick();
        if (!triggerElement.value || !contentElement.value) {
          throw new Error('Dropdown refs are not present');
        }
        if (
          triggerElement.value.childElementCount !== 1 ||
          contentElement.value.childElementCount !== 1
        ) {
          throw new Error('Dropdown slots should supply a single element');
        }
        popper = createPopper(
          triggerElement.value.firstElementChild as HTMLElement,
          contentElement.value.firstElementChild as HTMLElement,
          {
            placement: props.placement,
            modifiers: [
              { name: 'offset', options: { offset: [props.skidding, props.distance] } }
            ]
          }
        );
      }
    });

    onBeforeUnmount(() => popper?.destroy());

    function destroyPopper() {
      if (!props.visible) {
        popper?.destroy();
      }
    }

    return {
      triggerElement,
      contentElement,
      destroyPopper
    };
  }
});
