export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "applicantEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Email"])},
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant ID"])},
        "applicantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Name"])},
        "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants"])},
        "assignedToMeTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned to me"])},
        "averageScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Score"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
        "completionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion Date"])},
        "manageTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
        "resultsComingSoonSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your applicants' Snapshot video responses will be available at ", _interpolate(_named("availabilityDate"))])},
        "resultsComingSoonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon!"])},
        "resultsProgramHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot find any results based on the combination. The selected program did not opt-in to use the selected Snapshot test. Please try a different combination."])},
        "resultsProgramHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available to display"])},
        "resultsUserHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have access to Snapshot results for the selected program and test combination, please try a different combination or contact your administrator for permission."])},
        "resultsUserHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No access to results"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
        "selectCombinationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we can display the results, please select a program and admissions cycle from the above dropdown."])},
        "selectCombinationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a combination from the above menus"])}
      },
      "fr": {
        "applicantEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel du candidat"])},
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du candidat"])},
        "applicantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du candidat"])},
        "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats"])},
        "assignedToMeTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes soumissions"])},
        "averageScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score moyen"])},
        "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])},
        "completionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complété le"])},
        "manageTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer"])},
        "resultsComingSoonSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les réponses vidéos Snapshot de vos candidat(e)s seront disponibles le ", _interpolate(_named("availabilityDate"))])},
        "resultsComingSoonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible !"])},
        "resultsProgramHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'avons pas trouvé de résultats correspondant à cette combination. Le programme sélectionné n'a pas opté pour l'utilisation de l'entrevue Snapshot sélectionnée. Veuillez essayer une combinaison différente."])},
        "resultsProgramHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données disponibles"])},
        "resultsUserHasNoAccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accès aux résutlats Snapshot pour la combinaison de programmes et d'examens sélectionnées. Veuillez essayer une combinaison différente ou contacter votre administrateur pour une autorisation."])},
        "resultsUserHasNoAccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun accès aux résultats"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateurs"])},
        "scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
        "selectCombinationSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de pouvoir afficher des résultats, veuillez sélectionner un programme et une période d'admission dans le menu déroulant ci-dessus."])},
        "selectCombinationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une combinaison d'options dans les menus ci-dessus."])}
      }
    }
  })
}
