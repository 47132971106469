export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cycleTestLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission cycle, test and language"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program"])},
        "selectFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from list"])}
      },
      "fr": {
        "cycleTestLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'admission, examen et langue"])},
        "program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme"])},
        "selectFromList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner dans la liste"])}
      }
    }
  })
}
