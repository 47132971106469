
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import { unparse } from 'papaparse';
import { downloadFile, formatCsvDate } from '@/services/util';
import { initAmplitude, logEvent } from '@/services/amplitude';

import ChartMessage from '@/components/program/ChartMessage.vue';
import SchoolTestSelect from '@/components/program/SchoolTestSelect.vue';
import ManageSubmissionsTab from '@/components/program/ManageSubmissionsTab.vue';
import AssignedSubmissionsTab from '@/components/program/AssignedSubmissionsTab.vue';

export default defineComponent({
  name: 'ProgramApplicants',
  components: {
    ChartMessage,
    SchoolTestSelect,
    ManageSubmissionsTab,
    AssignedSubmissionsTab
  },
  setup() {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const isRater = computed(() => auth.isRater);
    const isAdmin = computed(() => auth.isSchoolAdmin);
    const i18n = useI18n();

    const mode = ref<'manage' | 'assigned-submissions'>('manage');

    const isTestSelected = computed(
      () => program.currentSchool !== undefined && program.currentTest !== undefined
    );

    const areResultsAvailable = computed(() => program.areResultsAvailable);
    const formattedResultsAvailableAt = computed(
      () => program.formattedResultsAvailableAt
    );
    const programHasAccess = computed(() => program.programHasAccess);
    const userHasAccess = computed(() => program.userHasAccess);

    const filteredSubmissions = computed(() => program.filteredSubmissions);

    const loadingData = computed(
      () => program.loadSubmissions.isRunning || program.loadRaters.isRunning
    );

    onMounted(() => {
      initAmplitude('program', auth.user?.id);
      if (isRater.value) {
        mode.value = 'assigned-submissions';
      }
    });

    async function downloadCsv() {
      const headers = [
        i18n.t('applicantName'),
        i18n.t('applicantEmail'),
        i18n.t('applicantId'),
        i18n.t('completionDate'),
        i18n.t('reviewers'),
        i18n.t('averageScore'),
        i18n.t('comments')
      ];
      const rows = program.augmentedSubmissions.map(row => [
        row.submission.user?.profile?.name,
        row.submission.user?.emails[0].address,
        row.submission.user?.id,
        formatCsvDate(row.submission.completedAt as string),
        row.raters
          ?.map(
            rater =>
              `${rater.profile?.name} | ${row.submission.ratings
                ?.filter(rating => rating.userId === rater.id)
                .map(r => r.score ?? '')}`
          )
          .join(' |\n'),
        row.averageScore,
        row.raters
          ?.map(
            rater =>
              `${rater.profile?.name} | ${row.submission.ratings
                ?.filter(rating => rating.userId === rater.id)
                .map(r => r.comment ?? '')}`
          )
          .join(' |\n')
      ]);
      const content = unparse({ fields: headers, data: rows });
      downloadFile(content, 'snapshot-submissions.csv', 'text/csv');

      logEvent({
        eventName: 'SS_EXPORT_RESULTS',
        userProperties: { userID: auth.user?.id },
        eventProperties: {
          programSelection: program.currentSchool?.id,
          programSelectionName: program.currentSchool?.name,
          snapshotTestId: program.currentTest?.id,
          snapshotTestVersion: program.currentTest?.name,
          snapshotTestLanguage: program.currentTest?.lang
        }
      });
    }

    return {
      t: i18n.t,
      isAdmin,
      isTestSelected,
      areResultsAvailable,
      formattedResultsAvailableAt,
      programHasAccess,
      userHasAccess,
      filteredSubmissions,
      loadingData,
      downloadCsv,
      mode
    };
  }
});
