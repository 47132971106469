export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant ID"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewed"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "snapshotCompletionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot Completion Date"])},
        "waitingForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for review"])}
      },
      "fr": {
        "applicantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du candidat"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "reviewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évalué"])},
        "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
        "snapshotCompletionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapsot complété le"])},
        "waitingForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente d'évaluation"])}
      }
    }
  })
}
