<template>
  <router-link
    :to="{
      name: 'program.assignApplicants',
      params: { raterId: rater?.id }
    }"
    class="bubble"
    :class="{
      blue: raterColor === 'blue',
      red: raterColor === 'red',
      teal: raterColor === 'teal',
      orange: raterColor === 'orange',
      'baby-blue': raterColor === 'baby-blue',
      green: raterColor === 'green'
    }"
  >
    <slot />
  </router-link>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/runtime-core';
import { User } from '@/interfaces/interfaces';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';

export default defineComponent({
  name: 'RaterBubble',
  props: {
    rater: {
      type: Object as PropType<User>,
      required: true
    }
  },
  setup(props) {
    const program = useStore(programStore);
    const raters = computed(() => program.raters);

    const colors = ['blue', 'red', 'teal', 'orange', 'baby-blue', 'green'];

    const raterColor = computed(() => {
      const raterIndex = raters.value?.indexOf(props.rater);
      if (raterIndex === undefined || raterIndex === -1) {
        throw new Error('Cannot find the index of provided rater');
      }
      return colors[raterIndex % colors.length];
    });

    return {
      raterColor
    };
  }
});
</script>

<style lang="postcss" scoped>
.bubble {
  @apply rounded-full p-2 text-sm text-gray-darker;
  @apply transition duration-300;
  &.blue {
    @apply bg-blue-lightest;
    &:hover {
      @apply bg-blue-lighter text-blue-darkest;
    }
    &:focus-visible {
      @apply outline-none ring-2 ring-blue;
    }
  }

  &.red {
    @apply bg-red-lightest;
    &:hover {
      @apply bg-red-lighter text-blue-darkest;
    }
    &:focus-visible {
      @apply outline-none ring-2 ring-blue;
    }
  }

  &.teal {
    @apply bg-teal-lightest;
    &:hover {
      @apply bg-teal-lighter text-blue-darkest;
    }
    &:focus-visible {
      @apply outline-none ring-2 ring-blue;
    }
  }

  &.orange {
    @apply bg-orange-lightest;
    &:hover {
      @apply bg-orange-lighter text-blue-darkest;
    }
    &:focus-visible {
      @apply outline-none ring-2 ring-blue;
    }
  }

  &.baby-blue {
    @apply bg-babyblue-lightest;
    &:hover {
      @apply bg-babyblue-lighter text-blue-darkest;
    }
    &:focus-visible {
      @apply outline-none ring-2 ring-blue;
    }
  }

  &.green {
    @apply bg-green-lightest;
    &:hover {
      @apply bg-green-lighter text-blue-darkest;
    }
    &:focus-visible {
      @apply outline-none ring-2 ring-blue;
    }
  }
}
</style>
