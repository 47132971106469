
import { computed, DeepReadonly, defineComponent, PropType, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { Submission, User } from '@/interfaces/interfaces';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';

import SearchInput from '@/components/common/SearchInput.vue';
import Checkmark from '@/components/common/Checkmark.vue';
import { programStore } from '@/store/program';

export default defineComponent({
  name: 'AssignRatersDropdown',
  props: {
    raters: { type: Array as PropType<DeepReadonly<User[]>>, required: true },
    submission: {
      type: Object as PropType<Submission>,
      required: false
    }
  },
  emits: ['onAssign'],
  components: {
    SearchInput,
    Checkmark
  },
  setup(props, { emit }) {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const currentUser = computed(() => auth.user);
    const ratersFilter = ref<string | null>(null);
    const isLoading = computed(() => program.assignRaters.isRunning);

    const filteredRaters = computed(() => {
      return ratersFilter.value === null
        ? program.ratersWithPermissions
        : program.ratersWithPermissions.filter(
            r =>
              ratersFilter.value === null ||
              r.profile?.name.toLowerCase().includes(ratersFilter.value.toLowerCase())
          ) ?? [];
    });

    function isRaterAssigned(rater: DeepReadonly<User>) {
      if (props.submission === undefined || props.submission.ratings === undefined) {
        return false;
      }
      return props.submission.ratings.some(r => r.userId === rater.id);
    }

    const selectedRaters = ref<Array<boolean | null>>([]);
    const selectedRaterIds = computed(() =>
      selectedRaters.value.flatMap((selected, index) =>
        selected ? filteredRaters.value[index].id : []
      )
    );

    function assignRaters() {
      emit('onAssign', selectedRaterIds.value);
      selectedRaters.value = [];
    }

    const isButtonDisabled = computed(
      () => isLoading.value || !selectedRaters.value.some(el => el)
    );

    return {
      t: useI18n().t,
      ratersFilter,
      filteredRaters,
      selectedRaters,
      isButtonDisabled,
      currentUser,
      isRaterAssigned,
      assignRaters,
      isLoading
    };
  }
});
