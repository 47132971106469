import { render } from "./ManageSubmissionsTab.vue?vue&type=template&id=06992148&scoped=true"
import script from "./ManageSubmissionsTab.vue?vue&type=script&lang=ts"
export * from "./ManageSubmissionsTab.vue?vue&type=script&lang=ts"

import "./ManageSubmissionsTab.vue?vue&type=style&index=0&id=06992148&lang=postcss&scoped=true"
script.render = render
script.__scopeId = "data-v-06992148"
/* custom blocks */
import block0 from "./ManageSubmissionsTab.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


export default script