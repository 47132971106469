<template>
  <button class="dashed-btn">
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DashedButton'
});
</script>

<style lang="postcss" scoped>
.dashed-btn {
  @apply py-1.5 px-2 rounded border border-dashed border-gray-light flex justify-center items-center;
  @apply text-xs text-blue-darkest transition duration-300;
  &:hover:not(:disabled) {
    @apply bg-gray-lighter border-gray;
  }
  &:focus,
  &:focus-visible {
    @apply outline-none ring-2 ring-blue;
  }
  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue;
  }
  &:disabled {
    @apply text-gray bg-gray-lighter border-gray;
  }
}
</style>
