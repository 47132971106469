<template>
  <div class="w-1/3">
    <SearchSubmissionInput />
  </div>
  <ul class="flex tab-list mt-6">
    <li>
      <button
        :class="{ 'tab-active': activeFilter === 'waiting-review' }"
        @click="activeFilter = 'waiting-review'"
      >
        {{ t('waitingForReview') }} ({{ waitingReviewSubmissions.length }})
      </button>
    </li>
    <li>
      <button
        :class="{ 'tab-active': activeFilter === 'reviewed' }"
        @click="activeFilter = 'reviewed'"
      >
        {{ t('reviewed') }} ({{ reviewedSubmissions.length }})
      </button>
    </li>
  </ul>
  <div class="overflow-x-auto mt-8">
    <a-table
      row-key="id"
      data-cy="my-applicants-data-table"
      :data="activeSubmissionsList"
      :columns="columns"
    >
      <template #cell-name="{ row }">
        <router-link
          :to="{
            name: 'program.submission',
            params: { submissionId: row.submission.id }
          }"
          @click="
            logEvent({
              eventName: 'SS_CLICKED_TO_REVIEW_APP',
              userProperties: {
                userID: auth.user?.id
              },
              eventProperties: { applicantUserId: row.submission.userId }
            })
          "
          class="link"
        >
          {{ row.submission.user.profile?.name }}
        </router-link>
      </template>
      <template #cell-id="{ row }">
        {{ row.submission.userId }}
      </template>
      <template #cell-email="{ row }">
        {{ row.submission.user.emails[0].address }}
      </template>
      <template #cell-completedAt="{ row }">
        {{ row.formattedCompletedAt }}
      </template>
      <template #cell-score="{ row }">
        {{ getUserScore(row.submission) }}
      </template>
      <template #cell-comment="{ row }">
        {{ getUserComment(row.submission) }}
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import { computed, DeepReadonly, defineComponent, PropType, ref } from 'vue';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { AugmentedSubmission, Submission } from '@/interfaces/interfaces';
import { useI18n } from '@/services/i18n';
import { logEvent } from '@/services/amplitude';

import SearchSubmissionInput from '@/components/program/SearchSubmissionInput.vue';

export default defineComponent({
  name: 'AssignedSubmissionsTab',
  props: {
    submissions: {
      type: Array as PropType<DeepReadonly<AugmentedSubmission[]>>,
      required: true
    }
  },
  components: {
    SearchSubmissionInput
  },
  setup(props) {
    const i18n = useI18n();
    const auth = useStore(authStore);
    const activeFilter = ref<'waiting-review' | 'reviewed'>('waiting-review');

    const columns = computed(() => {
      const baseColumns = [
        { name: 'name', label: i18n.t('name') },
        {
          name: 'id',
          label: i18n.t('applicantId')
        },
        { name: 'email', label: i18n.t('email') },
        { name: 'completedAt', label: i18n.t('snapshotCompletionDate') }
      ];
      if (activeFilter.value === 'waiting-review') {
        return baseColumns;
      } else if (activeFilter.value === 'reviewed') {
        return baseColumns.concat([
          {
            name: 'score',
            label: i18n.t('score')
          },
          {
            name: 'comment',
            label: i18n.t('comment')
          }
        ]);
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    const currentUsersSubmissions = computed(() => {
      if (auth.user === null) {
        throw new Error('No current user');
      }
      return props.submissions.filter(s => {
        return (
          s.submission.ratings !== undefined &&
          s.submission.ratings.length > 0 &&
          s.submission.ratings.some(r => r.userId === auth.user?.id)
        );
      });
    });

    const waitingReviewSubmissions = computed(() => {
      const submissions = currentUsersSubmissions.value.filter(s => {
        if (s.submission.ratings === undefined || auth.user === null) {
          return false;
        }
        const rating = s.submission.ratings?.find(r => r.userId === auth.user?.id);
        return rating?.score === null;
      });
      return submissions ?? [];
    });

    const reviewedSubmissions = computed(() => {
      const submissions = currentUsersSubmissions.value.filter(s => {
        if (s.submission.ratings === undefined || auth.user === null) {
          return false;
        }
        const rating = s.submission.ratings?.find(r => r.userId === auth.user?.id);
        return rating?.score !== null;
      });
      return submissions ?? [];
    });

    const activeSubmissionsList = computed(() => {
      if (activeFilter.value === 'waiting-review') {
        return waitingReviewSubmissions.value;
      } else if (activeFilter.value === 'reviewed') {
        return reviewedSubmissions.value;
      } else {
        throw new Error('Unexpected filter value');
      }
    });

    function getUserScore(submission: Submission) {
      if (submission.ratings === undefined) {
        return null;
      }
      const usersRating = submission.ratings.find(r => r.userId === auth.user?.id);
      return usersRating?.score ?? null;
    }

    function getUserComment(submission: Submission) {
      if (submission.ratings === undefined) {
        return null;
      }
      const usersRating = submission.ratings.find(r => r.userId === auth.user?.id);
      if (usersRating === undefined) {
        return null;
      } else if (usersRating.comment && usersRating.comment.length > 99) {
        return usersRating.comment.slice(0, 99).concat('...');
      } else {
        return usersRating.comment;
      }
    }

    return {
      t: i18n.t,
      auth,
      logEvent,
      activeFilter,
      columns,
      waitingReviewSubmissions,
      reviewedSubmissions,
      activeSubmissionsList,
      getUserScore,
      getUserComment
    };
  }
});
</script>

<style lang="postcss" scoped>
.tab-list {
  @apply border-b border-gray-lighter;
  & li {
    @apply mr-4 md:mr-6 lg:mr-10;

    & button {
      @apply py-4 transition-colors duration-300;

      &:hover {
        @apply text-gray;
      }

      &:focus-visible {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }

      /* Styling does not apply unless :moz-focusring is separated from :focus-visible, which is why it is repeated below */
      &:-moz-focusring {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }

      &:active {
        @apply text-blue;
      }

      &.tab-active {
        @apply text-blue cursor-default;
        box-shadow: rgb(77, 84, 160) 0 -0.2rem 0 inset;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "applicantId": "Applicant ID",
    "comment": "Comment",
    "email": "Email",
    "name": "Name",
    "reviewed": "Reviewed",
    "score": "Score",
    "snapshotCompletionDate": "Snapshot Completion Date",
    "waitingForReview": "Waiting for review"
  },
  "fr": {
    "applicantId": "ID du candidat",
    "comment": "Commentaire",
    "email": "Courriel",
    "name": "Nom",
    "reviewed": "Évalué",
    "score": "Score",
    "snapshotCompletionDate": "Snapsot complété le",
    "waitingForReview": "En attente d'évaluation"
  }
}
</i18n>
