<template>
  <div class="dropdown-card">
    <SearchInput
      label-sr-only
      :label="t('searchReviewersLabel')"
      data-cy="search-reviewers-input"
      v-model="ratersFilter"
    />
    <ul class="mt-5 pl-1 max-h-52 overflow-y-scroll">
      <li v-for="(rater, index) in filteredRaters" :key="rater.id" class="py-2">
        <div v-if="isRaterAssigned(rater)" class="flex items-center">
          <Checkmark class="w-3 text-blue" />
          <span class="ml-3 text-sm">
            {{ rater.profile?.name }}
            <span class="font-bold" v-if="currentUser?.id === rater.id">
              ({{ t('you') }})
            </span>
          </span>
        </div>
        <a-checkbox v-else v-model="selectedRaters[index]" :disabled="isLoading">
          {{ rater.profile?.name }}
          <span class="font-bold" v-if="currentUser?.id === rater.id">
            ({{ t('you') }})
          </span>
        </a-checkbox>
      </li>
    </ul>
    <div class="mt-3 text-right">
      <a-button :disabled="isButtonDisabled" @click="assignRaters()">
        <div v-if="isLoading" class="px-3">
          <a-spinner size="1.2rem" variant="neutral" />
        </div>
        <span v-else>
          {{ t('assign') }}
        </span>
      </a-button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, DeepReadonly, defineComponent, PropType, ref } from 'vue';
import { useI18n } from '@/services/i18n';
import { Submission, User } from '@/interfaces/interfaces';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';

import SearchInput from '@/components/common/SearchInput.vue';
import Checkmark from '@/components/common/Checkmark.vue';
import { programStore } from '@/store/program';

export default defineComponent({
  name: 'AssignRatersDropdown',
  props: {
    raters: { type: Array as PropType<DeepReadonly<User[]>>, required: true },
    submission: {
      type: Object as PropType<Submission>,
      required: false
    }
  },
  emits: ['onAssign'],
  components: {
    SearchInput,
    Checkmark
  },
  setup(props, { emit }) {
    const auth = useStore(authStore);
    const program = useStore(programStore);
    const currentUser = computed(() => auth.user);
    const ratersFilter = ref<string | null>(null);
    const isLoading = computed(() => program.assignRaters.isRunning);

    const filteredRaters = computed(() => {
      return ratersFilter.value === null
        ? program.ratersWithPermissions
        : program.ratersWithPermissions.filter(
            r =>
              ratersFilter.value === null ||
              r.profile?.name.toLowerCase().includes(ratersFilter.value.toLowerCase())
          ) ?? [];
    });

    function isRaterAssigned(rater: DeepReadonly<User>) {
      if (props.submission === undefined || props.submission.ratings === undefined) {
        return false;
      }
      return props.submission.ratings.some(r => r.userId === rater.id);
    }

    const selectedRaters = ref<Array<boolean | null>>([]);
    const selectedRaterIds = computed(() =>
      selectedRaters.value.flatMap((selected, index) =>
        selected ? filteredRaters.value[index].id : []
      )
    );

    function assignRaters() {
      emit('onAssign', selectedRaterIds.value);
      selectedRaters.value = [];
    }

    const isButtonDisabled = computed(
      () => isLoading.value || !selectedRaters.value.some(el => el)
    );

    return {
      t: useI18n().t,
      ratersFilter,
      filteredRaters,
      selectedRaters,
      isButtonDisabled,
      currentUser,
      isRaterAssigned,
      assignRaters,
      isLoading
    };
  }
});
</script>

<style lang="postcss" scoped>
.dropdown-card {
  @apply border border-gray-lighter rounded-lg shadow-md bg-white;
  @apply p-6 w-80;
}
</style>

<i18n>
{
  "en": {
    "assign": "Assign",
    "searchReviewersLabel": "Search for reviewers",
    "you": "you"
  },
  "fr": {
    "assign": "Assigner",
    "searchReviewersLabel": "Rechercher des examinateurs",
    "you": "moi"
  }
}
</i18n>
