export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
        "searchReviewersLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for reviewers"])},
        "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you"])}
      },
      "fr": {
        "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigner"])},
        "searchReviewersLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des examinateurs"])},
        "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moi"])}
      }
    }
  })
}
